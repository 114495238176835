<script setup>
import { useHomeStore } from "@/store/home";
const store = useHomeStore();

</script>

<template>
<div class="text-center mt-3">
  <h3 class="mb-3">Welcome <span class="fw-bold">{{ store.user.displayName || '' }}</span></h3>
</div>

</template>

<style scoped>


</style>